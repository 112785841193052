const React = require("react")
const ProviderSetup = require("./src/provider/provider-setup").default
const UniversalLayout = require("./src/layout/universal").default

exports.wrapRootElement = ({ element, props }) => {
  return <ProviderSetup {...props}>{element}</ProviderSetup>
}

exports.wrapPageElement = ({ element, props }) => {
  return <UniversalLayout {...props}>{element}</UniversalLayout>
}
