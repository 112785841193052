import React from "react"
import TabLink from "./tab-link"
import styled, { keyframes } from "styled-components"
import { Breakpoints } from "../util/breakpoints"

const highlightInKeyframes = keyframes`
  from {
    color: var(--textColor);
    background-color: var(--backgroundColor);
  }
  to {
    color: var(--linkColor);
    background-color: var(--themedBackgroundColor);
  }
`

const highlightOutKeyframes = keyframes`
  from {
    color: var(--linkColor);
    background-color: var(--themedBackgroundColor);
  }
  to {
    color: var(--textColor);
    background-color: var(--backgroundColor);
  }
`

const StyledTab = styled(TabLink)`
  font-size: large;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin: 10px;
  width: 100%;
  color: black;
  text-decoration: none;
  color: var(--textColor);
  background-color: var(--backgroundColor);
  border: none;

  @media ${Breakpoints.smallOrLarger} {
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    border: none;
    border-left: 1px solid black;
    border-right: 1px solid black;
    animation: ${highlightOutKeyframes} 0.5s ease-out;
  }

  &.tab-selected {
    font-weight: bold;
    color: var(--textColor);
    background-color: var(--backgroundColor);
  }

  // Needs to be defined after .tab-selected
  // or the active tab will blink out after the focus animation
  &:focus, &:hover {
    outline: none;
    color: var(--linkColor);
    background-color: var(--themedBackgroundColor);
    @media ${Breakpoints.smallOrLarger} {
      animation: ${highlightInKeyframes} 0.25s ease-out;
    }
  }
` 

export default function Tab(props) {
  return (
    <StyledTab
      className={props.isActiveTab ? "tab-selected" : ""}
      to={`/${props.tabSlug}`}
      data-qa={props["data-qa"] || "TabNavigator"}
    >
      {props.tabName}
    </StyledTab>
  )
}
