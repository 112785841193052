import React from "react"
import styled from "styled-components"
import getUserContent from "../../user-content"
import useIsClient from "../util/rehydration"

const Buffer = styled.div`
  padding-bottom: 30px;
`

const StyledFooter = styled.div`
  display: grid;
  grid-template-columns: auto;
  background-color: var(--darkBackgroundColor);
  padding: 10px;
  font-size: small;
  line-height: 100%;

  a {
    color: var(--darkLinkColor);
  }
`

export default function Footer(props) {
  // For addressing rehydration issue
  // https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
  const { isClient, key } = useIsClient()

  if ( !isClient ) return null
  return (
    <div key={key}>
      <Buffer />
      <StyledFooter id="footer" role="contentinfo" data-qa={"Footer"}>
        {getUserContent().footer}
      </StyledFooter>
    </div>
  )
}
