/* useSiteStyles
 *
 * Get the site styles as determined by user preferences
 */

import useUserPreferences from "./use-user-preferences"
import RGB from "../util/colors"
import SiteStyles from "../util/site-styles"

export default function useSiteStyles() {
  const userPreferences = useUserPreferences()
  const primaryThemeColor = new RGB(...userPreferences.primaryThemeColor)
  const backgroundColor = new RGB(...userPreferences.backgroundColor)
  return new SiteStyles(primaryThemeColor, backgroundColor)
}
