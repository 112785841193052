import RGB from "./colors.js"
import {
  minContrastRatio,
  scaleLuminance,
  scaleContrast,
} from "./color-accessibility"

const _scaleLum = (rgb, lum) =>
  new RGB(...scaleLuminance(rgb.toArray(), lum))

// Wraps scaleContrast for use with RGB
// Only scales the second color
// Reads "Scale rgb2 to contrast against background rgb1"
const _scaleCon = (rgb1, rgb2, contrast) => {
  const colors = scaleContrast(rgb1.toArray(), rgb2.toArray(), contrast, 0.0)
  return new RGB(...colors[1])
}

export default class SiteStyles {
  constructor(primaryThemeColor, backgroundColor) {
    this._initColors(primaryThemeColor, backgroundColor)
  }

  _initColors(primaryThemeColor, backgroundColor) {
    const white = new RGB(255, 255, 255)
    const grey = new RGB(127, 127, 127)
    const red = new RGB(255, 0, 0)
    backgroundColor = backgroundColor || white
    const bgColor = _scaleLum(backgroundColor, 0.90)
    const darkBgColor = _scaleLum(backgroundColor, 0.80)
    this.colors = {
      background: bgColor,
      themedBackground: white,
      imageBackground: white,
      darkBackground: darkBgColor,
      errorText: _scaleCon(bgColor, red, minContrastRatio.AAA.text),

      accent: _scaleCon(bgColor, primaryThemeColor, 2.0),
      disabledAccent: _scaleCon(bgColor, grey, 3.0),
      shareIcon: _scaleCon(bgColor, grey, 5.0),

      text: _scaleCon(bgColor, grey, 10.0),
      lightText: _scaleCon(bgColor, grey, minContrastRatio.AAA.text),

      link: _scaleCon(bgColor, primaryThemeColor, minContrastRatio.AAA.text),
      darkLink: _scaleCon(darkBgColor, primaryThemeColor, minContrastRatio.AAA.text),
    }
  }

}
