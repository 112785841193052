/* ModalLink
 *
 * Links for modals
 *
 * <ModalLink modalId={"my-modal"}>
 *   Go to My Modal
 * </ModalLink>
 */

import React from "react"
import useModal from "../hooks/use-modal"

export default function ModalLink(props) {
  const modal = useModal()
  const clickHandler = clickEvent => {
    // We just want to open the modal so Prevent the link from navigating
    clickEvent.preventDefault()

    modal.open(props.modalId)
  }
  return (
    <a onClick={clickHandler}
      href="#0"
      data-qa={props["data-qa"] || "ModalLink"}
    >
      {props.children}
    </a>
  )
}
