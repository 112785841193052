/* Slideshow
 * Props:
 *   images: Required. The images to use. Must be in src/images/
 *   timer: Optional (default=5). Time per image in seconds. -1 for infinite
C*   fadeDuration: Optional (default=1). Duration of the fade in seconds
 *   imageContainerStyle: Optional (default={}).
 *     Style object for the active image.
 *     Useful if you need to set constraints for irregular images.
 *     It's best to avoid having to use this.
 *   
 * Usage:
 *   <SlideShow
 *     images={["first.jpg", "second.png", "third.jpeg"]}
 *   />
 *
 *   <SlideShow
 *     images={["first.jpg", "second.png", "third.jpeg"]}
 *     timer={7}
 *     fadeDuration={3}
 *   />
 */

import React, { useState } from "react"
import useInterval from "../hooks/use-interval"
import ImageDeck from "./image-deck"

export default function SlideShow(props) {
  const [topIdx, setTopIdx] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const oneSecondDelay = 1000
  const timer = props.images.length > 1 ? props.timer : -1

  const clockTick = () => {
    let ticks = seconds + 1
    if (ticks === timer) {
      setTopIdx( (topIdx + 1) % props.images.length )
      ticks = 0
    }
    setSeconds(ticks)
  }

  useInterval(clockTick, oneSecondDelay)

  return (
    <ImageDeck
      images={props.images}
      topIdx={topIdx}
      fadeDuration={props.fadeDuration}
      data-qa={props["data-qa"] || "SlideShow"}
      imageContainerStyle={props.imageContainerStyle}
    />
  )


}

SlideShow.defaultProps = {
  fadeDuration: 1,
  timer: 5,
  imageContainerStyle: {},
}
