/* useUserPreferences
 *
 * Get the user preferences from gatsby-config
 */

import { useStaticQuery, graphql } from "gatsby"

export default function useUserPreferences() {
  const queryData = useStaticQuery(userPreferencesQuery)
  return queryData.site.siteMetadata.userPreferences
}

const userPreferencesQuery = graphql`
  query {
    site {
      siteMetadata {
        userPreferences {
          splashImages
          language
          purchase
          currency
          primaryThemeColor
          backgroundColor
        }
      }
    }
  }
`
