/* storage
 *
 * Basic tools for managing storage
 */

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const IS_CLIENT = typeof window === "object"

function storageAvailable(type) {
  if (!IS_CLIENT) {
    return false
  }
  var storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch(e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      (storage && storage.length !== 0);
  }
}

class FakeStorage {
  constructor() { this.storage = {}; }
  setItem(key, value) { this.storage[key] = value; }
  getItem(key) { return this.storage[key] !== undefined ? this.storage[key] : null; }
  removeItem(key) { delete this.storage[key]; }
  clear() { this.storage = {}; }
}

export {
  storageAvailable,
  FakeStorage,
}
