/* useLocalStorageObject
 *
 * Hook for using an object in local storage
 * Effectively lets you create a mini local storage in local storage
 * Interface mirrors localStorage
 * Only use one of them per key and stick it in the context.
 *
 * Usage:
 *
 *  const myStorage = useLocalStorageObject("my-storage")
 *  myStorage.setItem("foo", 23)
 *  const foo = myStorage.getItem("foo")
 *  myStorage.removeItem("foo")
 *  mystorage.setItem("bar", "aaaaaaa")
 *  mystorage.clear()
 */

import { useReducer } from "react"
import { storageAvailable, FakeStorage } from "../util/storage"

const IS_CLIENT = typeof window !== undefined

let fallbackStorage
const getFallbackStorage = () => {
  if (!fallbackStorage) {
    fallbackStorage = new FakeStorage()
  }
  return fallbackStorage
}

const getStorageReducer = (storage, storageKey) => (prevState, action) => {
  const setPersistent = state => {
    storage.setItem(storageKey, JSON.stringify(state))
  }
  let nextState

  switch (action.type) {
    case "set":
      // payload structure: { key: keyToSet, value: valueToSet}
      const newItem = {}
      newItem[action.payload.key] = action.payload.value
      nextState = {...prevState, ...newItem}
      setPersistent(nextState)
      return nextState
    case "remove":
      // payload structure: {key: keyToRemove}
      nextState = {}
      for (const [k, v] of Object.entries(prevState)) {
        if (k !== action.payload.key) {
          nextState[k] = v
        }
      }
      setPersistent(nextState)
      return nextState
    case "clear":
      nextState = {}
      setPersistent(nextState)
      return nextState
    default:
      return prevState;
  }
}

export default function useLocalStorageObject(key) {

  const fullStorage = IS_CLIENT && storageAvailable("localStorage") ?
    localStorage :
    getFallbackStorage()

  let initialValue
  try {
    initialValue = JSON.parse(fullStorage.getItem(key))
  } catch (err) {
    initialValue = null
  } finally {
    if (typeof initialValue !== "object" || initialValue === null) {
      initialValue = {}
      fullStorage.setItem(key, initialValue)
    }
  }

  const [storageObj, storageObjDispatch] = useReducer(
    getStorageReducer(fullStorage, key),
    initialValue,
  )

  return {
    keys: () => Object.keys(storageObj),
    values: () => Object.values(storageObj),
    entries: () => Object.entries(storageObj),
    setItem: (k, v) => storageObjDispatch({
      type: "set",
      payload: { key: k, value: v },
    }),
    getItem: k => storageObj[k] !== undefined ? storageObj[k] : null,
    removeItem: k => storageObjDispatch({
      type: "remove",
      payload: { key: k }
    }),
    clear: () => storageObjDispatch({ type: "clear" }),
  }

}
