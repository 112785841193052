/* useSiteMetadata
 *
 * Get the siteMetadata from gatsby-config
 */

import { useStaticQuery, graphql } from "gatsby"

export default function useSiteMetadata() {
  const queryData = useStaticQuery(siteMetadataQuery)

  if (queryData.site.siteMetadata.siteUrl.endsWith("/")) {
    const url = queryData.site.siteMetadata.siteUrl
    queryData.site.siteMetadata.siteUrl = url.substring(0, url.length - 1)
  }

  return queryData.site.siteMetadata
}

const siteMetadataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        owner
        siteUrl
        description
        twitterUsername
        keywords
      }
    }
  }
`
