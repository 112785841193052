/* color-accessibility.js
 *
 * Color-related tools for WCAG (Web Content Accessibility Guidelines) 2.1
 * Includes constants and measurement tools
 */

function _validateColorArray(rgb) {
  const errmsg = `${rgb} is not an array of RGB integer values in 0-255`
  if (!Array.isArray(rgb) || rgb.length !== 3) {
    throw TypeError(errmsg)
  }
  for (const c of rgb) {
    if (
      typeof c !== "number" ||
      c < 0 ||
      c > 255 ||
      Math.floor(c) !== c
    ) {
      throw TypeError(errmsg)
    }
  }
}

function _validateUnitInterval(n, lb = 0.0, ub = 1.0) {
  if (typeof n !== "number" || n < lb || n > ub) {
    throw TypeError(`${n} is not a number between ${lb} and ${ub}`)
  }
}

function _average(rgb1, rgb2) {
  let av = [undefined, undefined, undefined]
  for (let i = 0; i < 3; i++) {
    if (rgb1[i] === 255 || rgb2[i] === 255) {
      av[i] = Math.ceil( (rgb1[i]+rgb2[i])/2.0 )
    } else {
      av[i] = Math.floor( (rgb1[i]+rgb2[i])/2.0 )
    }
  }
  return av
}

function _equal(rgb1, rgb2) {
  return [0, 1, 2].every(i => rgb1[i] === rgb2[i])
}

// WCAG contrast ratios
// https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html
const minContrastRatio = {
  // Contrast ratio for regular vision
  // Based on ISO-9241-3 and ANSI-HFES-100-1988
  // Legible for users with normal (20/20) vision
  A: {
    text: 3.0,
    largeText: 3.0,
  },
  // Min contrast ratio for AA standard
  // Legible for users with moderately low visual acuity (20/40 vision)
  AA: {
    text: 4.5,
    largeText: 3.0,
  },
  // Min contrast ratio for AAA standard
  // Legible for users with lower visual acuity (20/80 vision)
  AAA: {
    text: 7.0,
    // Applying the approach discussed in the docs to increase the AA large text ratio,
    // although I didn't find this value explicitly
    largeText: 3.0*(1 + (7.0-4.5)/4.5),
  },
}

/* relativeLuminance
 * Params:
 *  rgb: array of red, green, blue values in [0, 255]
 * Returns a value in [0, 1]. Black has value 0 and white has value 1
 * Used to ensure contrast for accessibility
 * https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html
 * https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html#dfn-relative-luminance
 */
function relativeLuminance(rgb) {
  _validateColorArray(rgb)
  const [red, green, blue] = rgb
  const contribution = colorComponent =>
    colorComponent <= 0.03928 ?
    colorComponent/12.92 :
    Math.pow(((colorComponent+0.055)/1.055), 2.4)
  return 0.2126*contribution(red/255.0) +
    0.7152*contribution(green/255.0) +
    0.0722*contribution(blue/255.0)
}

/* contrastRatio
 * Params:
 *  rgb1: array of red, green, blue values in [0, 255]
 *  rgb2: array of red, green, blue values in [0, 255]
 * Returns a value >= 1 of the contrast ratio between the two input colors
 * Used to ensure contrast for accessibility
 * https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html
 * https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html#dfn-contrast-ratio
 */
function contrastRatio(rgb1, rgb2) {
  _validateColorArray(rgb1)
  _validateColorArray(rgb2)
  const relLum1 = relativeLuminance(rgb1),
    relLum2 = relativeLuminance(rgb2)
  return relLum1 >= relLum2 ?
    (relLum1 + 0.05)/(relLum2 + 0.05) :
    (relLum2 + 0.05)/(relLum1 + 0.05)
}

/* scaleLuminance
 * Params:
 *  rgb: array of red, green, blue values in [0, 255]
 *  targetLuminance: relative luminance between 0 and 1, inclusive
 * Returns an RGB array with the specified luminence,
 *  where the new color is as close as possible to the original
 * https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html
 * https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html#dfn-relative-luminance
 */
function scaleLuminance(rgb, targetLuminance) {
  _validateColorArray(rgb)
  _validateUnitInterval(targetLuminance)
  let upperBound = [255, 255, 255]
  let lowerBound = [0, 0, 0]
  let current = [rgb[0], rgb[1], rgb[2]]
  let luminance
  do {
    luminance = relativeLuminance(current)
    if (luminance > targetLuminance) {
      upperBound = current
      current = _average(current, lowerBound)
    } else if (luminance < targetLuminance) {
      lowerBound = current
      current = _average(current, upperBound)
    }
  } while (
    luminance !== targetLuminance &&
    !_equal(current, upperBound) &&
    !_equal(current, lowerBound)
  )
  return current
}

/* scaleContrast
 * Params:
 *  rgb1: array of red, green, blue values in [0, 255]
 *  rgb2: array of red, green, blue values in [0, 255]
 *  targetcontrast: desired contrast ratio between 0 and 21
 *  scaleFirst (optional): relative scaling on the first RGB value.
 *   Optional. Default is 0. Must be between 0 and 1.
 *   A value lower than 0.5 will change the second color more than the first.
 *   A value higher than 0.5 will change the first value more than the second.
 *   Values of 0 and 1 will leave the first and second colors, respectively, unchanged.
 * Returns colors that have at least the specified contrast ratio.
 * Does not decrease contrast.
 */
function scaleContrast(rgb1, rgb2, targetContrast, scaleFirst = 0.0) {
  _validateColorArray(rgb1)
  _validateColorArray(rgb2)
  _validateUnitInterval(targetContrast, 0.0, 21.0)
  _validateUnitInterval(scaleFirst)
  if (contrastRatio(rgb1, rgb2) >= targetContrast) {
    return [rgb1, rgb2]
  }
  let lightFirst = true
  let [lighter, darker] = [rgb1, rgb2]
  let [lighterLum, darkerLum] = [relativeLuminance(lighter), relativeLuminance(darker)]
  let scale = scaleFirst
  if (lighterLum < darkerLum) {
    lightFirst = false
    let tmp = lighter
    lighter = darker
    darker = tmp
    tmp = lighterLum
    lighterLum = darkerLum
    darkerLum = tmp
    scale = 1.0 - scale
  }

  let newDarkerLum = (0.05*(scale*targetContrast - scale - targetContrast + 1) +
    lighterLum*(1 - scale) +
    darkerLum*scale) /
    (targetContrast - scale*targetContrast + scale)
  let newLighterLum = targetContrast*(newDarkerLum + 0.05) - 0.05
  if (newDarkerLum < 0.0 || newLighterLum > 1.0) {
    throw RangeError(
      `Unable to generate colors with the given params. ` +
      `Validation indicates it's possible for some values of scaleFirst. ` +
      `Note that adjusting scaleFirst will affect both output colors. ` +
      `rgb1: ${rgb1} rgb2: ${rgb2} ` +
      `targetContrast: ${targetContrast} scaleFirst: ${scaleFirst}`
    )
  }

  const newLighter = scaleLuminance(lighter, newLighterLum)
  const newDarker = scaleLuminance(darker, newDarkerLum)
  return lightFirst ? [newLighter, newDarker] : [newDarker, newLighter]
}

export {
  minContrastRatio,
  relativeLuminance,
  contrastRatio,
  scaleLuminance,
  scaleContrast,
}
