/* AccentedButton
 */

import React from "react"
import styled, { keyframes } from "styled-components"
import { Breakpoints } from "../util/breakpoints"

const HighlightIn = keyframes`
  from {
    background-color: var(--background-color);
  }
  to {
    background-color: var(--themedBackgroundColor);
  }
`

const HighlightOut = keyframes`
  from {
    background-color: var(--themedBackgroundColor);
  }
  to {
    background-color: var(--background-color);
  }
`

const StyledButton = styled.button`
  background-color: var(--backgroundColor);
  border: solid;
  border-radius: 16px;
  border-color: var(--accentColor);

  font-size: large;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 8px;
  text-decoration: none;

  &:disabled {
    background-color: var(--backgroundColor);
    border: solid;
    border-radius: 16px;
    border-color: var(--disabledAccentColor);
    color: var(--lightTextColor);
  }

  &:focus, &:hover:not([disabled]) {
    cursor: pointer;
    outline: none;
    border: double;
    border-color: var(--accentColor);
    background-color: var(--themedBackgroundColor);
  }

  &::-moz-focus-inner {
    border: none;
  }

  @media ${Breakpoints.smallOrLarger} {
    animation: ${HighlightOut} 0.5s ease-out;

    &:focus, &:hover {
      animation: ${HighlightIn} 0.25s ease-out;
    }
  }
`

export default function AccentedButton(props) {
  return (
    <StyledButton
      {...props}
      data-qa={props["data-qa"] || "AccentedButton"}
    >
      {props.children}
    </StyledButton>
  )
}
