import React from "react"

import GlobalStyle from "./global-style"
import Metadata from "./metadata"
import CartProvider from "./cart-provider"
import ModalProvider from "./modal-provider"

export default function ProviderSetup(props) {

  return (
    <>
      <Metadata />
      <GlobalStyle>
        <CartProvider>
          <ModalProvider>
            {props.children}
          </ModalProvider>
        </CartProvider>
      </GlobalStyle>
    </>
  )
}
