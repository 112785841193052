/* ImageDeck
 * Props:
 *   images: Required. The images to use. Must be in src/images/
 *   topIdx: Optional (default=0). Index of starting image.
 *   fadeDuration: Optional (default=1). Duration of the fade in seconds
 *   imageContainerStyle: Optional (default={}).
 *     Style object for the active image.
 *     Useful if you need to set constraints for irregular images.
 *     It's best to avoid having to use this.
 *   
 * Usage:
 *   <ImageDeck
 *     images={["dog.jpg", "pig.png", "bread.jpeg"]}
 *   />
 *
 *   <ImageDeck
 *     images={["dog.jpg", "pig.png", "bread.jpeg"]}
 *     topIdx={2}
 *     fadeDuration={3}
 *   />
 */

import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { Breakpoints } from "../util/breakpoints"
import DynamicImage from "./dynamic-image"

const fadeOutKeyframes = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const fadeInKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledImageDeck = styled.div`
  position: relative;
  background-color: var(--backgroundColor);

  .fade-out {
    animation: ${fadeOutKeyframes} ${props => props.fadeDuration}s forwards ease-in;
  }

  .fade-in {
    animation: ${fadeInKeyframes} ${props => props.fadeDuration}s forwards ease-out;
  }

  .top {
    width: 100%;
    position: relative;
    z-index: 0;
  }

  .previous {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    @media ${Breakpoints.smallOrLarger} {
      margin-right: 32px;
    }
  }

  .bottom {
    display: none;
  }
`

export default function ImageDeck(props) {
  const [topIdx, setTopIdx] = useState(props.topIdx)
  const [prevIdx, setPrevIdx] = useState(props.topIdx)

  // Adjust indices when parent changes top
  useEffect(() => {
    if (topIdx !== props.topIdx) {
      setPrevIdx(topIdx)
      setTopIdx(props.topIdx)
    }
  }, [topIdx, props.topIdx])

  const getClass = idx => {
    if (topIdx === prevIdx) {
      switch (idx) {
        case topIdx:
          return `top`;
        default:
          return `bottom`;
      }
    }
    else {
      switch (idx) {
        case topIdx:
          return `top fade-in`;
        case prevIdx:
          return `previous fade-out`;
        default:
          return `bottom`;
      }
    }
  }

  const displayImages = Array.from(Array(props.images.length).keys()).map(idx =>
    <div
      key={idx}
      className={getClass(idx)}
    >
      <DynamicImage
        src={props.images[idx]}
        shouldShowBackground={false}
        containerStyle={props.imageContainerStyle}
      />
    </div>
  )

  return (
    <StyledImageDeck
      fadeDuration={props.fadeDuration}
      data-qa={props["data-qa"] || "ImageDeck"}
    >
      {displayImages}
    </StyledImageDeck>
  )


}

ImageDeck.defaultProps = {
  topIdx: 0,
  fadeDuration: 1,
  imageContainerStyle: {},
}
