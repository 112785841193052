/* ItemDetails
 *
 *  Usage:
 *
 *    <ItemDetails
 *      title="Hint of food"
 *      src="tall.jpg"
 *      longDescription={(<>
 *        <h3>Description</h3>
 *        <p>
 *          You can put as much info as you need to here.
 *          The modal will scroll if necessary.
 *        </p>
 *      </>)}
 *    />
 *
 * Expects src image to be in src/images/
 */

import React from "react"
import styled from "styled-components"
import DynamicImage from "./dynamic-image"

const FullWidthModal = styled.div`
  width: 80vw;
  padding-bottom: 24px;
  background-color: var(--backgroundColor);
`

// Need an object to pass the size into DynamicImage
const imageSize = {
  maxWidth: "100%",
  maxHeight: "30vh",
}
const ImageContainer = styled.div(imageSize)

const LongDescription = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

export default function ItemDetails(props) {

  return (
    <FullWidthModal data-qa={props["data-qa"] || "ItemDetails"}>
      {props.title && <h2 data-qa={"ItemTitle"}>{props.title}</h2>}
      <ImageContainer data-qa={"ItemMainImage"}>
        <DynamicImage
          src={props.src}
          containerStyle={imageSize}
          alt={props.title}
        />
      </ImageContainer>
      <LongDescription data-qa={"ItemInfo"}>
        {props.longDescription}
      </LongDescription>
    </FullWidthModal>
  )
}
