/* GoogleCalendar
 * https://support.google.com/calendar/answer/41207?hl=en
 * Pass in the iframe src
 */
import React from "react"
import styled from "styled-components"

const StyledCalendar = styled.iframe`
  display: block;
  margin: auto;
  border: solid 1px #777;
  width: min(90vw, 1200px);
  height: min(50vh, 1000px);
`

export default function GoogleCalendar(props) {
  if (!props.src) {
    throw ReferenceError("prop 'src' not defined")
  }
  return <StyledCalendar {...props}></StyledCalendar>
}

GoogleCalendar.defaultProps = {
  title: "Interactive Google Calendar",
  frameBorder: "0",
  allowFullScreen: "",
  "aria-hidden": "false",
  "tab-index": "0",
  loading: "lazy",
  scrolling: "no",
  "data-qa": "GoogleCalendar",
}
