/* Thumbnail
 *
 * DynamicImage specifically for thumbnails
 *
 * Props:
 *   src: image filename (no dirs)
 *   alt: alt text (defaults to filename de-slugified)
 *   containerStyle: size spec {width: "css width", height: "css height"}
 *
 * Example:
 *   <DynamicImage src="representative_image.jpg" alt="foo" />
 *   <DynamicImage
 *     containerStyle={{width: "300px", height: "300px"}}
 *     src="representative_image.jpg"
 *     alt="foo"
 *   />
 *
 */

import React from "react"
import DynamicImage from "./dynamic-image"

/*******************************************************************************
 * Thumbnail
 ******************************************************************************/

export default function Thumbnail(props) {

  const containerStyle = {
    ...props.containerStyle,
  }

  return (
    <DynamicImage
      {...props}
      containerStyle={containerStyle}
      data-qa={props["data-qa"] || "ThumbnailImage"}
    />
  )
}
