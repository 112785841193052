import React from "react"

import Overlay from "../components/overlay"
import Footer from "../components/footer"

export default function UniversalLayout(props) {

  return (
    <div id="page">
      <Overlay />
      {props.children}
      <Footer />
    </div>
  )

}
