/* Overlay
 */

import React from "react"
import styled from "styled-components"

const StyledOverlay = styled.div`
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
`

export default function Overlay(props) {
  return (
    <StyledOverlay
      id="overlay"
      data-qa={props["data-qa"] || "Overlay"}
    />
  )
}
