/* GoogleMap
 * On maps.google.com, search for your location
 * Select "Share" pass in src
 */
import React from "react"
import styled from "styled-components"

const StyledMap = styled.iframe`
  display: block;
  margin: ${props => props.margin};
  border: 0;
  width: ${props => props.width};
  height: ${props => props.height};
  aspect-ratio: ${props => props.aspectRatio};
`

export default function GoogleMap(props) {
  if (!props.src) {
    throw ReferenceError("prop 'src' not defined")
  }
  return (
    <StyledMap {...props}></StyledMap>
  )
}

GoogleMap.defaultProps = {
  title: "Interactive Google Map",
  frameBorder: "0",
  allowFullScreen: "",
  "aria-hidden": "false",
  "tab-index": "0",
  loading: "lazy",
  scrolling: "no",
  "data-qa": "GoogleMap",
  margin: "auto",
  width: "min(90vw, 600px)",
  height: "min(50vh, 450px)",
  aspectRatio: "unset",
}
