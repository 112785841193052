/* ModalProvider
 *
 * Provides open/close functions
 *
 * Usage:
 *
 *   import ModalProvider from "[DIR]/modal-provider"
 *   ...
 *   <ModalProvider>
 *     <p>Stuff here can use the cart context</p>
 *   </ModalProvider>
 */

import React, { useState } from "react"
import ModalContext from "../util/modal-context"

export default function ModalProvider(props) {
  const [active, setActive] = useState(null)

  function isOpen(modalId) {
    return modalId ? active === modalId : !!active
  }

  function close() {
    const page = document.getElementById("page")
    const overlay = document.getElementById("overlay")
    overlay.style.display = "none"
    page.style.filter = "blur(0)"
    page.style.overflow = "auto"
    setActive(null)
  }

  function open(modalId) {
    if (isOpen()) {
      close()
    }
    const page = document.getElementById("page")
    const overlay = document.getElementById("overlay")
    overlay.style.display = "block"
    page.style.filter = "blur(3px)"
    page.style.overflow = "hidden"
    setActive(modalId)
  }

  const modal = {
    isOpen: isOpen,
    open: open,
    close: close,
  }

  return (
    <ModalContext.Provider value={modal}>
      {props.children}
    </ModalContext.Provider>
  )
}
