/* DisplayItemList
 *
 * Props:
 *   items: map
 *     keys: item name
 *     values: map:
 *       keys (all optional):
 *        primaryInfo,
 *        description,
 *        image,
 *        details: (connects item to an ItemDetails modal) map:
 *          itemId: (required slug)
 *          detailsContent: Some JSX. If not there will use primaryInfo and description
 *
 * Example:
 *   <DisplayItemList items={new Map([
 *
 *     ["Scallops over fettuccine", new Map(Object.entries({
 *       primaryInfo: (<>
 *         $14.99
 *       </>), description: (<>
 *         <i>Saut&eacute;ed scallops over hearty garlic-parmesan fettuccine,
 *         with a side of tender broiled brussel sprouts
 *         and our famous homemade biscuits.</i>
 *       </>), image: "scallops.jpg"
 *     }))],
 *   
 *     ["Roasted potatoes", new Map(Object.entries({
 *       primaryInfo: (<>
 *         $4.99
 *       </>), description: (<>
 *         <i>Impossibly crispy on the outside, seductively tender on the inside.
 *         These taters are guaranteed to keep your kids busy for a few minutes
 *         until they're devoured.</i>
 *       </>), image: "taters.jpg"
 *     }))],
 *   
 *     ["Item with details", new Map(Object.entries({
 *       primaryInfo: (<>
 *         $4.99
 *       </>), description: (<>
 *         <i>Click on the image or item name to see details.</i>
 *       </>), image: "tall.jpg",
 *       details: new Map(Object.entries({
 *         itemId: "sample-item-with-details",
 *         detailsContent: (<>
 *           <h3>About item details</h3>
 *           <p>
 *             You can put whatever you want here.
 *             The modal will scroll if necessary.
 *           </p>
 *         </>)
 *       }))
 *     }))],
 *   
 *   ])} />
 */

import React from "react"
import styled from "styled-components"
import { Breakpoints } from "../util/breakpoints"
import DisplayItem from "./display-item"

const StyledDisplayItemList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  @media ${Breakpoints.mediumOrLarger} {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    grid-gap: 15px;
  }
`

export default function DisplayItemList(props) {
  // For addressing rehydration issue
  // https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/

  const itemNames = Array.from(props.items.keys())
  const value = (name, key) => props.items.get(name).get(key)

  const displayItems = itemNames.map(name => (
    <DisplayItem
      key={name}
      name={name}
      link={value(name, "link")}
      image={value(name, "image")}
      primaryInfo={value(name, "primaryInfo")}
      description={value(name, "description")}
      details={value(name, "details")}
      purchase={props.purchase}
    />
  ))

  return (
    <StyledDisplayItemList data-qa={props["data-qa"] || "DisplayItemList"}>
      {displayItems}
    </StyledDisplayItemList>
  )
}
