/* CartProvider
 *
 * Sets the cart and defines the cart interface.
 *
 * Usage:
 *
 *   import CartProvider from "[DIR]/cart-provider"
 *   ...
 *   <CartProvider>
 *     <p>Stuff here can use the cart context</p>
 *   </ModalProvider>
 *
 *   import useShoppingCart from "../hooks/use-shopping-cart"
 *   const cart = useShoppingCart()
 *   const hasFoo = cart.has("foo")
 *   const nFoos = cart.getQuantity("foo")
 *   const totalItems = cart.getQuantity()
 *   cart.setQuantity("foo", 5)
 *   cart.incrementQuantity("foo")
 *   cart.decrementQuantity("foo")
 *   cart.clear()
 *   ...
 *   <button onClick={() => cart.decrementQuantity("foo")}>-</button>
 *     {cart.getQuantity("foo")}
 *   <button onClick={() => cart.incrementQuantity("foo")}>+</button>
 */

import React from "react"
import CartContext from "../util/cart-context"
import useLocalStorageObject from "../hooks/use-local-storage-object"

export default function CartProvider(props) {
  const cartStorage = useLocalStorageObject("shopping-cart")

  function has(item) {
    return cartStorage.getItem(item) !== null
  }

  function items() {
    return cartStorage.keys()
  }

  function getQuantity(item = null) {
    let quantity
    if (!!item) {
      quantity = has(item) ? cartStorage.getItem(item).quantity : 0
    } else {
      const sumReducer = (partialSum, nextItem) => partialSum + getQuantity(nextItem)
      quantity = cartStorage.keys().reduce(sumReducer, 0)
    }
    return quantity
  }

  function setQuantity(item, quantity) {
    quantity = parseInt(quantity) >= 0 ? parseInt(quantity) : 0
    if (has(item) && quantity === 0) {
      cartStorage.removeItem(item)
    } else {
      cartStorage.setItem(item, { "quantity": quantity })
    }
  }

  function incrementQuantity(item) {
    setQuantity(item, getQuantity(item) + 1)
  }

  function decrementQuantity(item) {
    setQuantity(item, getQuantity(item) - 1)
  }

  function clear() {
    return cartStorage.clear()
  }

  const statefulCart = {
    has: has,
    items: items,
    getQuantity: getQuantity,
    setQuantity: setQuantity,
    incrementQuantity: incrementQuantity,
    decrementQuantity: decrementQuantity,
    clear: clear,
  }

  return (
    <CartContext.Provider value={statefulCart}>
      {props.children}
    </CartContext.Provider>
  )
}
