/* Modal
 *
 * Component based on react-modal.
 * Uses the context to open and close the modal
 *
 * Usage:
 *
 *    <Modal id="my-modal" contentLabel="My Modal">
 *      <p>Modal content</p>
 *    </Modal>
 *
 *    Use the ModalLink component to open modals.
 *
 */

import React, { useEffect } from "react"
import ReactModal from "react-modal"
import GlobalStyle from "../provider/global-style"
import styled from "styled-components"
import AccentedButton from "./accented-button"
import useSiteStyles from "../hooks/use-site-styles"
import useModal from "../hooks/use-modal"
import useEventListener from "../hooks/use-event-listener"

const Centered = styled.div`
  margin-top: 10px;
  text-align: center;
`

export default function Modal(props) {

  useEffect(() => {
    ReactModal.setAppElement("#page")
  })

  const modal = useModal()

  // Ensure modal closes properly on back button
  // Avoids blurry page on back button when modal is open
  useEventListener("popstate", () => {
    if (modal.isOpen) {
      modal.close()
    }
  })

  const colors = useSiteStyles().colors

  // Styles passed in as a prop to React Modal
  const modalStyles = {
    content: {
      top         : "40%",
      left        : "50%",
      right       : "auto",
      bottom      : "auto",
      marginTop   : "48px",
      marginRight : "-50%",
      paddingTop  : "0",
      transform   : "translate(-50%, -50%)",
      backgroundColor : colors.background.toString(),
  
      // Allow scrolling
      maxHeight   : "80vh",
      overflowY   : "auto",
    },
  }

  return (
    <ReactModal
      isOpen={modal.isOpen(props.id)}
      onRequestClose={modal.close}
      style={modalStyles}
      contentLabel={props.contentLabel}
      data-qa={props["data-qa"] || "Modal"}
    >
      <GlobalStyle>
        {props.children}
        <Centered>
          <AccentedButton
            onClick={modal.close}
            data-qa={"CloseModalButton"}
          >
            Close
          </AccentedButton>
        </Centered>

        {/* A hack to create space on the bottom when scrolling might be involved */}
        {/* Otherwise, the close button hits the bottom of the modal */}
        {props.scrolly && <p>&nbsp;</p>}
      </GlobalStyle>

    </ReactModal>
  )
}
