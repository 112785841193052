/* RGB
 * Bascially a vector for red, green, and blue
 *
 * The scale function will yield a color that has a given lightness.
 * (This is different from a simple vector scale because of the limits on RGB values)
 */

class RGB {
  constructor(red, green, blue) {
    this.red = Math.floor(red)
    this.green = Math.floor(green)
    this.blue = Math.floor(blue)
  }

  /* copy
   * Returns a deep copy of the color
   */
  copy() {
    return new RGB(this.red, this.green, this.blue)
  }

  /* value
   * Returns a value in [0, 1]. Black has value 0 and white has value 1
   */
  value() {
    const colorDist = _distance([this.red, this.green, this.blue]),
      whiteDist = 441.67295593006370984949
    return colorDist/whiteDist
  }

  /* scale
   * Params:
   *   percentWhite: float between 0 and 1
   * Returns a copy that's scaled to the given percent white
   *
   * Note:
   *   This ensures the resulting color has the lightness value,
   *   not that the color is a direct scale of the original
   */
  scaled(percentWhite) {
    // White is sqrt(3) if 1 >= R, G, B, >= 0
    let targetLength = percentWhite*Math.sqrt(3),
      origColor = new RGB(this.red, this.green, this.blue),
      newColor = new RGB(0, 0, 0),
      nMaxedOut,
      sumOfSquares,
      scalar,
      marginOfErr = 0.0001;
    for (let c of _colorNames) {
      if (origColor[c] <= 0) {
        origColor[c] = 0.001 // No impact unless necessary
      }
    }

    // Note: we're using 1 as dim length instead of 255 for simplicity
    for (let c of _colorNames) {
      origColor[c] = origColor[c]/255.0
    }

    // Try to just scale the vector
    sumOfSquares = 0
    for (let c of _colorNames) {
      sumOfSquares += origColor[c]*origColor[c]
    }
    scalar = Math.sqrt(targetLength*targetLength) / Math.sqrt(sumOfSquares)
    for (let c of _colorNames) {
      newColor[c] = Math.min(scalar*origColor[c], 1.0)
    }

    // Case: perfect scaled vector is OOB (had to shorten using min above)
    // So we find the nearest approximation
    const length = color => _distance([color.red, color.green, color.blue])
    while ( length(newColor) < targetLength - marginOfErr ) {
      sumOfSquares = 0
      nMaxedOut = 0
      for (let c of _colorNames) {
        if (newColor[c] < 1.0 - marginOfErr) {
          sumOfSquares += origColor[c]*origColor[c]
        } else {
          nMaxedOut += 1
        }
      }
      scalar = Math.sqrt(targetLength*targetLength - nMaxedOut) / Math.sqrt(sumOfSquares)
      for (let c of _colorNames) {
        newColor[c] = Math.min(origColor[c]*scalar, 1.0)
      }
    }

    for (let c of _colorNames) {
      newColor[c] = Math.floor(255.0*newColor[c])
    }
    return newColor
  }

  /* mixed
   * Params:
   *   ratio: the proportion of otherColor.
   *     Use a negative ratio to move away from the other color.
   *     E.g., grey.mixed(black, -0.5) === lightGrey
   * Returns a copy of the color mixed with the other color.
   */
  mixed(otherColor, ratio=0.5) {
    let mixedColor = new RGB(0, 0, 0)
    for (let c of _colorNames) {
      mixedColor[c] = Math.floor(ratio*otherColor[c] + (1.0-ratio)*this[c])
    }
    return mixedColor
  }

  /* inverted
   * Returns a copy of the inverse of the color
   */
  inverted() {
    return new RGB(255 - this.red, 255 - this.green, 255 - this.blue)
  }

  /* toString
   * Returns a css-compatible string representing the color
   */
  toString() {
    return `rgb(${this.red},${this.green},${this.blue})`
  }

  /* toArray
   * Returns an array or [red, green, blue]
   */
  toArray() {
    return [this.red, this.green, this.blue]
  }
}

const _colorNames = ["red", "green", "blue"]

function _distance(arr) {
  let sumOfSquares = 0.0
  for (let n of arr) {
    sumOfSquares += n*n
  }
  return Math.sqrt(sumOfSquares)
}

export default RGB
