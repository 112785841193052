
import React from "react"
import styled from "styled-components"
import { Breakpoints } from "../util/breakpoints"

const StyledTitle = styled.h1`
  grid-area: title;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  column-gap: 10px;
  row-gap: 0;
  margin-top: 0;
  margin-bottom: 0;

  @media ${Breakpoints.smallOrLarger} {
    row-gap: 5px;
  }
`

const TitleWord = styled.div`
  font-size: large;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: normal;

  &::first-letter {
    font-size: x-large;
  }

  @media ${Breakpoints.smallOrLarger} {
    font-size: x-large;

    &::first-letter {
      font-size: xx-large;
    }
  }
`

export default function Title(props) {
  const titleJSX = text => {
    let words = text.split(" "),
      wordsJSX = words.map((word) =>
        <TitleWord key={word}>
          {word}
        </TitleWord>
      )
    return <>{wordsJSX}</>
  }

  return (
    <StyledTitle data-qa={props["data-qa"] || "TitleText"}>
      {titleJSX(props.text)}
    </StyledTitle>
  )
}
