/* SSR-safe ways to get elements
 */

const isClient = typeof window === "object"

function getElementById(elementId) {
  if (isClient) {
    return document.getElementById(elementId)
  }
}

export {
  getElementById,
}
