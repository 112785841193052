import React from "react"
import Tab from "./tab"
import styled from "styled-components"
import { Breakpoints } from "../util/breakpoints"

const StyledTabList = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  border-collapse: collapse;

  @media ${Breakpoints.smallOrLarger} {
    grid-template-columns: repeat(4, auto);
  }

  @media ${Breakpoints.mediumOrLarger} {
    grid-template-columns: repeat(6, auto);
  }
`

const CenteredContainer = styled.div`
  display: grid;
  @media ${Breakpoints.smallOrLarger} {
    justify-items: center;
  }
`

export default function TabList(props) {

  const tabsJSX = props.tabs.map(tab =>
    <Tab
      key={tab.slug}
      tabSlug={tab.slug}
      tabName={tab.name}
      isActiveTab={tab.slug === props.activeTab}
    />
  )

  return (
    <CenteredContainer>
      <StyledTabList data-qa={props["data-qa"] || "TabList"}>
        {tabsJSX}
      </StyledTabList>
    </CenteredContainer>
  )
}
