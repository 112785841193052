import React from "react"
import styled from "styled-components"
import TabLink from "/src/components/tab-link"
import { Breakpoints } from "/src/util/breakpoints"

const Donate = ({ isCondensed, isVisible }) => {
  const QuoteText = styled.p`
    text-align: center;
    font-style: italic;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 0;
    @media ${Breakpoints.smallOrLarger} {
      margin-left: 200px;
      margin-right: 200px;
    }
    @media ${Breakpoints.mediumOrLarger} {
      margin-left: 300px;
      margin-right: 300px;
    }
  `
  const QuoteAttribution = styled(QuoteText)`
    font-style: normal;
    text-align: right;
    margin-top: 0;
    margin-bottom: 16px;
  `
  const DonateGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: auto;
    row-gap: 12px;
    justify-content: center;
    margin-bottom: 30px;
  
    @media ${Breakpoints.smallOrLarger} {
      grid-template-columns: auto auto;
      column-gap: 36px;
    }
  `
  const DonateButton = styled.button`
    color: white;
    background-color: rgb(31, 75, 180);
    border-radius: 12px;
    padding: 10px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: x-large;
    width: 8em;
    cursor: pointer; 
  `
  const Paragraph = styled.p`
    @media ${Breakpoints.smallOrLarger} {
      margin-left: 175px;
      margin-right: 175px;
    }
  `
  return (
    <>
      <div style={{ display: isVisible ? '' : 'none' }}>
        <h2>Donate & Support</h2>
        <iframe src="https://oakparkmuslimcommunity.ddock.gives?showBg" height="2346" frameborder="0" width="100%"></iframe>
      </div>
      <article style={{ display: isVisible ? 'none' : '' }}>
        <h2>Donate & Support</h2>
        <QuoteText>
          Those who spend their wealth by night and day,
          privately and publicly,
          will receive their reward from their Lord.
          They have nothing to fear, nor shall they grieve.
        </QuoteText>
        <QuoteAttribution>
          Surah Al-Baqara (2), verse 274
        </QuoteAttribution>
        <DonateGrid>
          <DonateButton
            onClick={() => { window.location = "/donate#TabContent"; }}
          >
            <strong>Donate</strong>&ensp;<i className="fas fa-donate"></i>
          </DonateButton>
        </DonateGrid>
        <div style={{ display: isCondensed ? 'none' : '' }}>
          <Paragraph>
            Contributions to the Oak Park Muslim Community
            are deemed charitable under section 501(a)
            of the Internal Revenue Code as an
            organization described in Section 501(c)(3).
          </Paragraph>
          <section>
            <h2>Do we have a mosque?</h2>
            <Paragraph>
              We don't have a mosque yet.
              Through fundraising efforts,
              our goal is to establish a mosque.
            </Paragraph>
            <Paragraph>
              Our activities and meetings are held at
              the Oak Park Public Library,
              Kinfolk CoLab, or
              local area community centers.
            </Paragraph>
            <Paragraph>
              We are actively in the <strong>planning, fundraising, and organizing</strong> stages
              of establishing a mosque in the local area.
              We are in the exploration phase for an exact location and
              talks are ongoing with zoning lawyers, realtors, and architects.
              We require your support to make this mosque a reality.
            </Paragraph>
          </section>
          <section>
            <h2>Volunteer opportunities</h2>
            <Paragraph>
              Use your expertise,
              volunteer your time,
              or just attend a meeting. <TabLink
                to="/contact"
              >Contact us</TabLink> for more info.
            </Paragraph>
          </section>
        </div>
      </article>
    </>
  );
};

export default Donate;

