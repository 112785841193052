/* QuantityInput
 *
 *  Used for number input
 *
 *  Props:
 *    name: item name required for accessibility
 *    getQuantity: function returning a number
 *    setQuantity: function taking a quantity
 *    identifier: optional identifier used as part of ids
 *
 *  TODO: Ensure unique ids and remove idenfifier param
 *    Currently, unless an identifier is specified,
 *    a uuid is generated from the input.
 *    There will be duplicate ids if two QuantityInput components
 *    are for the same item.
 */

import React from "react"
import styled from "styled-components"
import { v5 as uuidv5 } from "uuid"
import { getElementById } from "../util/get-element"
import AccentedButton from "./accented-button"

const ContainingGrid = styled.div`
  display: inline-grid;
  grid-template-columns: min-content min-content min-content;
  column-gap: 0px;
  align-items: center;
`

const QuantityButton = styled(AccentedButton)`
  height: 2em;
  width: 2em;
  line-height: 0;
  text-align: center;
  letter-spacing: 0;
`

const IncreaseButton = styled(QuantityButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

const DecreaseButton = styled(QuantityButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`

const TextInput = styled.input`
  width: 3em;
  height: 1.5em;
  line-height: 0;
  text-align: center;
  border: solid;
  border-right: none;
  border-left: none;
  border-radius: 0;
  border-color: var(--accentColor);
`

export default function QuantityInput(props) {
  // https://www.npmjs.com/package/uuid#uuidv5name-namespace-buffer-offset
  // Using an arbitrary but consistent uuid as the namespace
  const identifier = uuidv5(props.name, "dcb9f8b5-eaa9-44b8-ac89-85bfd34b8134")

  const setDisplayQuantity = newQuantity => {
    const quantityElement = getElementById(identifier)
    quantityElement.value = newQuantity.toString()
  }

  const updateQuantity = quantity => {
    if (quantity >= 0) {
      setDisplayQuantity(quantity)
      props.setQuantity(quantity)
    }
  }

  return (
    <ContainingGrid>
      <DecreaseButton
        aria-label={`Decrease the quantity of ${props.name}`}
        onClick={() => updateQuantity(props.getQuantity() - 1)}
        data-qa={"DecreaseQuantityButton"}
      >&ndash;</DecreaseButton>
      <TextInput
        aria-label={`Input the quantity of ${props.name}`}
        id={identifier}
        type="text"
        defaultValue={props.getQuantity().toString()}
        onChange={e => updateQuantity(parseInt(e.target.value)) }
        data-qa={"QuantityTextInput"}
      />
      <IncreaseButton
        aria-label={`Increase the quantity of ${props.name}`}
        onClick={() => updateQuantity(props.getQuantity() + 1)}
        data-qa={"IncreaseQuantityButton"}
      >+</IncreaseButton>
    </ContainingGrid>
  )
}
