/* PurchaseActions
 *
 *  Props:
 *    name: required for accessibility
 *    product: required for shopping cart interaction
 */

import React from "react"
import useShoppingCart from "../hooks/use-shopping-cart"
import styled from "styled-components"
import QuantityInput from "./quantity-input"

const Quantity = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  grid-column-gap: 6px;
`

export default function PurchaseActions(props) {

  const cart = useShoppingCart()

  return (
    <Quantity>
      <QuantityInput
        name={props.name}
        getQuantity={() => cart.getQuantity(props.product.id)}
        setQuantity={quantity => cart.setQuantity(props.product.id, quantity)}
      />
    </Quantity>
  )
}
