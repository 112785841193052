/*******************************************************************************
 * filenameToText
 ******************************************************************************/
function filenameToText(filename) {
  const basename = filename.split(".")[0]
  const deslugify = slug => slug.replace(/_|-/g, " ")
  return deslugify(basename)
}

export {
  filenameToText,
}
